<script lang="ts">
import SkeletonAnimation from "./SkeletonAnimation.svelte";

	export let message: string = ""

</script>
<div class="aspect-video w-full relative">
	<SkeletonAnimation />
	{#if message}
		<div class="inset-0 flex absolute items-center justify-center">
			<span class="font-bold">
				{message}
			</span>
		</div>
	{/if}
</div>