import { ReactiveObject } from "./ReactiveObject";


export interface IMemoryCache<T>{
	items: T[];
}

export class MemoryCache<K> extends ReactiveObject<IMemoryCache<K>>{
	public itemId: keyof K;
	constructor(items: K[], id: keyof K){
		super({
			items,
		});
		this.itemId = id;
	}


	getById(id: K[keyof K]): K | undefined{
		return this.getItem("items").find(item => item[this.itemId] === id);
	}

	add(item: K){
		if(this.getById(item[this.itemId])){
			return;
		}
		this.updateItem("items", _items => {
			_items.push(item);
			return _items;
		})
	}
	
	find(predicate: (item: K) => boolean){
		return this.getItem("items").find(predicate);
	}

	remove(predicate: (item: K) => boolean){
		this.updateItem("items", _items => {
			_items = _items.filter(item => !predicate(item));
			return _items;
		})
	}
}
