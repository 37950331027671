<script lang="ts">
	import { castApi, keyboard, overlayModal } from "@common/basicStores";
    import { clearCache } from "@common/clearCache";
    import { Keys } from "@common/keyboard";
    import { getLocalStorageItem, setLocalStorageItem } from "@common/localstorage";
	import ErrorView from "@components/Error/ErrorView.svelte";

	import Layout from "@components/Layout/Layout.svelte";
import ModulesLoader from "@components/Utils/ModulesLoader.svelte";
	import { onMount } from "svelte";
	import Routes from "./Routes/Routes.svelte";

	onMount(() => {
		castApi.init();
		if(getLocalStorageItem("oldCacheCleared") !== "true") {
			clearCache("request-cache")
			clearCache("invidious")
			setLocalStorageItem("oldCacheCleared", "true")
		}
	});


</script>
<Layout isModalOpen={$overlayModal.isOpen}>
	
	<Routes />
	<ModulesLoader />

	<svelte:fragment slot="modal">
		{#if $overlayModal.component}
			<svelte:component
				this={$overlayModal.component}
				{...$overlayModal.props || {}}
			/>
		{:else}
			<ErrorView error={new Error("No Modal Provided")} />
		{/if}
	</svelte:fragment>
</Layout>
