import type { IVideoMeta } from "./invidiousApi";

export interface ICacheVideoOptions {
	videoId: string;
	downloadUrl: string;
	meta?: {
		[key: string]: string;
	};
	onProgress?: (
		progress: number,
		downloadedBytes: number,
		speed: number
	) => void;
	onDone?: () => void;
	onError?: (error: Error) => void;
}

export interface ICacheVideoReturn {
	pause: () => void;
	resume: () => void;
	stop: () => void;
	start: () => void;
	getStats: () => {
		totalBytes: number;
		downloaded: number;
		speed: number;
	};
}

export interface ICacheRequestProps {
	[key: string]: string | undefined;
	expiration?: string;
}

export const VIDEO_CACHE_KEY = "video-cache";
export const VIDEO_META_CACHE_KEY = "video-meta-cache";

export const downloadProcessing = new Map<string, boolean>();

export const getVideoCacheStorage = (): Promise<Cache> => {
	return caches.open(VIDEO_CACHE_KEY);
}
export const getVideoMetaCacheStorage = (): Promise<Cache> => {
	return caches.open(VIDEO_META_CACHE_KEY);
}

export const cacheYoutubeVideo = async (videoMeta: IVideoMeta, options: ICacheVideoOptions) => {
	try{

		if(downloadProcessing.get(videoMeta.videoId)){
			throw new Error("Downloading in progress");
		}

		downloadProcessing.set(videoMeta.videoId, true);
		const metaCache = await getVideoMetaCacheStorage();
		const videoCache = await getVideoCacheStorage();
		await cacheYoutubeMeta(videoMeta, metaCache)
		
		await cacheVideo(options, videoCache)

		downloadProcessing.set(videoMeta.videoId, false);

	}catch(err){
		downloadProcessing.set(videoMeta.videoId, false);
		throw err;
	}
}

export const getCachedVideoMeta = async (videoId: string, onlyMeta: boolean = false): Promise<IVideoMeta | undefined> => {
	const metaCache = await getVideoMetaCacheStorage();
	const metaCacheRequest = getYoutubeMetaCacheRequest(videoId);
	const meta = await metaCache.match(metaCacheRequest);
	if(!meta){
		return undefined;
	}
	const videoMeta =  (await meta.json()) as IVideoMeta;


	if(!videoMeta){
		return undefined;
	}

	if(onlyMeta){
		return videoMeta;
	}
	if(await isCachedVideoAvailable(videoId)){
		videoMeta.formatStreams = [{
			...(videoMeta.formatStreams[
				videoMeta.formatStreams.length - 1
			] || {}),
			url: `/get-cached-video/${videoId}`,
		}]

		videoMeta.isCachedResponse = true;

		return videoMeta;
	}

	return undefined;
}


export const getCachedVideo = async (videoId: string):Promise<Response|undefined> => {
	const request = getYoutubeCacheRequest(videoId);
	const videoCache = await getVideoCacheStorage();
	const video = await videoCache.match(request);
	return video;
}


export const isCachedVideoAvailable = async (videoId: string) => {
	const cacheStorage = await getVideoCacheStorage();
	const request = getYoutubeCacheRequest(videoId);
	const cached = await cacheStorage.match(request);
	return !!cached;
}

export const removeYoutubeCache = async (videoId: string) => {
	const cacheStorage = await getVideoCacheStorage();
	const request = getYoutubeCacheRequest(videoId);
	const metaCacheStorage = await getVideoMetaCacheStorage();
	const metaRequest = getYoutubeMetaCacheRequest(videoId);
	await cacheStorage.delete(request);
	await metaCacheStorage.delete(metaRequest);
}



export const cacheYoutubeMeta = async (videoMeta: IVideoMeta, cache: Cache) => {
	const request = getYoutubeMetaCacheRequest(videoMeta.videoId);
	const response = getYoutubeMetaCacheResponse(videoMeta);
	await cache.put(request, response);
}


export const getYoutubeMetaCacheRequest = (videoId: string, props: ICacheRequestProps = {} as any, init?: RequestInit) => {
	const request = new Request(`/video-meta-cache/${videoId}${Object.keys(props).length > 0 ? `?${new URLSearchParams(props as any)}` : ""
		}`, init)
	return request
}

export const getYoutubeCacheRequest = (videoId: string, props: ICacheRequestProps = {} as any, init?: RequestInit) => {
	const request = new Request(`/video-cache/${videoId}${Object.keys(props).length > 0 ? `?${new URLSearchParams(props as any)}` : ""
		}`, init)
	return request
}

export const getYoutubeMetaCacheResponse = (videoMeta: IVideoMeta) => {
	return new Response(
		JSON.stringify(videoMeta),
		{
			headers: {
				"Content-Type": "application/json"
			}
		}
	)
}



export const cacheVideo = async ({
	videoId,
	meta,
	onProgress,
	onDone,
	onError,
	downloadUrl,
}: ICacheVideoOptions, cache: Cache): Promise<void> => {
	const downloadUrlInstance = new URL(downloadUrl);
	downloadUrlInstance.searchParams.set("local", "true");
	const response = await fetch(downloadUrlInstance.toString(), {
		mode: "no-cors"
	});

	const request = getYoutubeCacheRequest(videoId, meta);
	await cache.put(request, response);
	return
};


export const cacheVideoMeta = (videoMeta: IVideoMeta) => {

}