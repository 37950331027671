import type { IVideoItem } from "@components/VideoList/commonTypes";
import { ReactiveObject } from "./ReactiveObject";
import {
	createInstance
} from 'localforage'
import { uid } from "uid";
import { getDefaultThumbnail, getVideoMeta, getWatchUrl, type IVideoMeta } from "./invidiousApi";
import type { IExtendedWritableEvents } from "./ExtendedWritable";

export interface IChangeEvents<T, U = any> extends IExtendedWritableEvents{
	add: {
		item: T
		index: number
		data?: U
	},
	remove: {
		item: T
		index: number
		data?: U
	},
	update: {
		item: T
		index: number
		data?: U
	}
}

export type ICustomVideoListEvents = IChangeEvents<ICustomVideoListVideoItem, undefined>
export type ICustomVideoListManagerEvents = IChangeEvents<ICustomVideoList, ICustomVideoListEvents>

export interface ICustomVideoListVideoItem {
	id: string;
	video: IVideoItem;
	addedAt: number;
	lastViewed: number;
	playedTimes: number;
	parents: string[];
}


export interface ICustomVideoList {
	videos: ICustomVideoListVideoItem[];
	id: string;
	title: string;
	description?: string;
	createdAt: number;
	updatedAt: number;
	forkedFrom?: string;
	cached?: boolean;
	cacheExpiry?: number;
	temporary?: boolean;
}

export class CustomVideoList extends ReactiveObject<ICustomVideoList, ICustomVideoListEvents>{

	public storage;
	public loaded: boolean | Promise<boolean> = false;
	private listManager: CustomVideoListManager | undefined;
	constructor(data: ICustomVideoList, listManager?: CustomVideoListManager) {
		super(data);
		this.storage = createInstance({
			name: `customVideoList-${this.get().id}`
		});
		this.listManager = listManager;
	}


	async addYoutubeVideo(videoMeta?: IVideoMeta) {

		if(!videoMeta) return
		await this.addVideo({
			video: {
				videoId: videoMeta.videoId,
				title: videoMeta?.title || "",
				author: videoMeta?.author || "",
				thumbnail: getDefaultThumbnail(videoMeta?.videoThumbnails) as any,
				viewCountText: (videoMeta as any)?.viewCountText || "",
				authorId: videoMeta?.authorId || "",
				authorUrl: videoMeta?.authorUrl || '',
				videoUrl: getWatchUrl(videoMeta.videoId),
			},
			addedAt: Date.now(),
			id: uid(),
			lastViewed: 0,
			parents: [],
			playedTimes: 0,
		})
	}

	async shuffle(){
		this.update((state) => {
			state.videos = state.videos.sort(() => Math.random() - 0.5);
			return state;
		})
		await this.save()
	}


	addVideo(video: ICustomVideoListVideoItem, triggerEvent: boolean = true) {

		if (this.hasVideoId(video?.video?.videoId)) {
			return
		}
		this.update((state) => {
			state.videos.push(video);
			state.updatedAt = Date.now();
			if(triggerEvent){
				this.dispatch("add", {
					index: state.videos.length - 1,
					item: video
				})
			}
			return state;
		});


	}

	async updateListDataInManager(){
		this.listManager?.update(state => {
			const currentListId = this.get().id;
			const listIndex = state.lists.findIndex(l => l.id === currentListId);
			if(listIndex !== -1){
				state.lists[listIndex] = this.getStateWithoutVideos();
			}
			return state;
		})
		await this.listManager?.saveLists();
	}

	removeVideo(targetId: string, triggerEvent: boolean = true) {
		this.update((state) => {
		
			const removedIndex = state.videos.findIndex(v => v.id === targetId);
			
			if(state.videos[removedIndex]) {

				if(triggerEvent){
					this.dispatch("remove", {
						index: removedIndex,
						item: state.videos[removedIndex]
					})
				}
			}
			
			state.updatedAt = Date.now();
			state.videos = state.videos.filter(v => v.id !== targetId);
			return state;
		});
	}

	removeVideoId(id: string, triggerEvent: boolean = true) {
		this.update((state) => {

			const removedIndex = state.videos.findIndex(v => v.video.videoId === id);
			
			state.updatedAt = Date.now();
			if(state.videos[removedIndex] && triggerEvent) {
				this.dispatch("remove", {
					index: removedIndex,
					item: state.videos[removedIndex]
				})
			}

			state.videos = state.videos.filter(v => v.video.videoId !== id);
			return state;
		})
	}


	removeVideos(targetId: string) {
		this.update((state) => {
			state.videos = state.videos.filter(v => v.id !== targetId);
			return state;
		});
	}

	removeVideosWithVideoIds(videoIds: string[]) {
		this.update((state) => {
			state.videos = state.videos.filter(v => !videoIds.includes(v.video.videoId));
			return state;
		});
	}

	addVideos(videos: IVideoItem[]) {
		return videos.map(v => !this.hasVideoId(v.videoId) && this.addVideo({
			id: uid(),
			video: v,
			addedAt: Date.now(),
			lastViewed: Date.now(),
			playedTimes: 0,
			parents: []
		}));
	}

	updateVideo(targetId: string, updater: (video: ICustomVideoListVideoItem) => ICustomVideoListVideoItem, triggerEvent: boolean = true) {
		this.update((state) => {
			const targetIndex = state.videos.findIndex(v => v.id === targetId);
			if (targetIndex === -1) {
				return state;
			}
			state.videos[targetIndex] = updater(state.videos[targetIndex]);
			state.updatedAt = Date.now();


			if(state.videos[targetIndex] && triggerEvent) {
				this.dispatch("update", {
					index: targetIndex,
					item: state.videos[targetIndex]
				})
			}

			return state;
		});
	}

	getVideo(targetId: string) {
		return this.get().videos.find(v => v.id === targetId);
	}

	getStateWithoutVideos(): ICustomVideoList{
		const {videos, ...state} = this.get();
		return state as any;
	}

	async save() {
		if(this.get().temporary) return;
		await this.storage.setItem(this.get().id, this.get());
	}
	async load() {
		let resolve: (value: boolean) => void = () => { };
		this.loaded = new Promise<boolean>(res => resolve = res);
		const data: ICustomVideoList | null = await this.storage.getItem(this.get().id);
		if (data) {
			this.set(data);
		}
		resolve(true);
		this.loaded = true;
	}

	getItemByVideoId(videoId: string) {
		return this.get().videos.find(v => v.video.videoId === videoId);
	}

	hasVideoId(videoId: string) {
		return this.get().videos.some(v => v.video.videoId === videoId);
	}

	listToUrl() {
		const videosIds = this.get().videos.map(v => v.video.videoId);
		const videoIdList = videosIds.join(',');
		const url = new URL(window.location.origin);
		url.searchParams.set('title', this.getItem("title"));
		url.searchParams.set('description', this.getItem("description") || "");
		url.searchParams.set("listId", this.getItem("id"));
		url.pathname = "/sharelist"
		url.hash = btoa(videoIdList);
		return url;
	}

	static async urlToList(url: URL) {
		const videoIdList = atob(url.hash.slice(1)).split(',');
		const title = url.searchParams.get('title');
		const description = url.searchParams.get('description');
		const listId = url.searchParams.get('listId');
		const fetchedVideoMeta = videoIdList.map(videoId => {
			return getVideoMeta(videoId).then(v => {

				const temp: IVideoItem = {
					title: v.title,
					videoUrl: getWatchUrl(v.videoId),
					thumbnail: getDefaultThumbnail(v.videoThumbnails as any) as any,
					author: v.author,
					authorUrl: v.authorUrl,
					viewCountText: (v as any).viewCountText,
					authorId: v.authorUrl?.split("/").pop() || "",
					videoId: v.videoId,
				}

				return temp

			}).catch(e => {
				console.error(e);
				return null
			})
		}).filter(e => e !== null);

		const _fetchedList = await Promise.all(fetchedVideoMeta).then(videos => {
			return videos.filter(v => v !== null).map(v => {
				return {
					id: uid(),
					video: v,
					addedAt: Date.now(),
					lastViewed: Date.now(),
					playedTimes: 0,
					parents: []
				}
			})
		})
		return new CustomVideoList({
			id: listId || uid(),
			videos: _fetchedList as any,
			title: title || "shared list",
			description: description || "",
			createdAt: Date.now(),
			updatedAt: Date.now()
		})
	}

	async merge(list: CustomVideoList){
		this.update((state) => {
			state.videos = state.videos.concat(list.get().videos.filter(v2 => {
				return !state.videos.some(v1 => v1.video.videoId === v2.video.videoId);
			}));
			return state;
		})

		await this.save()

	}
}


export interface ICustomVideoListManager {
	listInstances: CustomVideoList[];
	lists: Omit<ICustomVideoList, "videos">[];
	config?: {
		defaultList?: string;
	}

}

export class CustomVideoListManager extends ReactiveObject<ICustomVideoListManager>{
	public storage = createInstance({
		name: "customVideoListsManager"
	});
	public listsLoaded: boolean | Promise<boolean> = false;
	public configLoaded: boolean | Promise<boolean> = false;
	constructor() {
		super({
			listInstances: [],
			lists: [],
		});
	}

	async getDefaultList(): Promise<CustomVideoList | undefined> {

		if (!(await this.listsLoaded)) {
			await this.loadLists();
		}

		if (!(await this.configLoaded)) {
			await this.loadConfig();
		}

		const defaultListId = this.get().config?.defaultList;
		if (defaultListId) {
			const list = this.getList(defaultListId);
			if (!(await list?.loaded)) {
				await list?.load();
			}
			return list;
		} else {
			const list = await this.newList({
				title: "playlist"
			});

			await this.setDefaultList(list.getItem("id"))
			return list
		}
	}

	async getWatchLaterList(): Promise<CustomVideoList | undefined> {

		const watchLaterListTitle = "Watch Later";
		const watchLaterListId = "watchLater";

		if (!(await this.listsLoaded)) {
			await this.loadLists();
		}


		const defaultListId = watchLaterListId
		const list = this.getList(defaultListId);
		if (defaultListId && list) {

			if (!(await list?.loaded)) {
				await list?.load();
			}
			return list;
		} else {
			const list = await this.newList({
				title: watchLaterListTitle,
				id: watchLaterListId
			});
			return list
		}
	}


	async getAndLoadList(listId: string): Promise<CustomVideoList | undefined> {
		if (!(await this.listsLoaded)) {
			await this.loadLists();
		}
		const defaultListId = listId
		const list = this.getList(defaultListId);
		if (defaultListId && list) {

			if (!(await list?.loaded)) {
				await list?.load();
			}
			return list;
		}

		return undefined;
	}


	async setDefaultList(id: string) {
		this.update(state => {
			state.config = {
				defaultList: id
			}
			return state;
		})

		await this.saveConfig();
	}

	getList(listId: string): CustomVideoList | undefined {
		return this.get().listInstances.find(l => l.get().id === listId);
	}
	


	async loadInstance(listId: string) {
		await this.getList(listId)?.load()
	}
	async saveInstance(listId: string) {
		await this.getList(listId)?.save()
	}


	async loadLists() {

		if(this.listsLoaded) return;
		let resolve: (val: boolean) => void = () => { };
		this.listsLoaded = new Promise<boolean>(res => resolve = res);
		const lists: ICustomVideoListManager["lists"] | null = await this.storage.getItem("lists");
		if (lists) {
			this.update(state => {
				state.lists = lists;
				state.listInstances = lists.map(l => new CustomVideoList({
					...l,
					videos: []
				}, this));
				return state
			})
		}

		resolve(true);
		this.listsLoaded = true;
	}
	async saveLists() {
		const data = this
			.get()
			.listInstances
			.filter(l => {
				return !l.get().temporary
			})
			.map(l => l.getStateWithoutVideos())

		await this.storage.setItem("lists", data);
	}

	async newList(listOpts: Partial<ICustomVideoList> = {} as any) {

		const _listOpts: ICustomVideoList = {
			id: uid(),
			title: "playlist",
			createdAt: Date.now(),
			updatedAt: Date.now(),
			videos: [],
			...listOpts,
		}
		const list = new CustomVideoList(_listOpts, this);
		this.update(state => {
			state.lists.push(_listOpts);
			state.listInstances.push(list);
			return state;
		})

		await list.save();
		await this.saveLists();

		return list;
	}

	async removeList(listId: string) {
		this.update(state => {
			state.lists = state.lists.filter(l => l.id !== listId);
			state.listInstances = state.listInstances.filter(l => l.get().id !== listId);
			return state;
		})
	}

	async saveConfig() {
		await this.storage.setItem("config", this.get().config);
	}

	async loadConfig() {

		let resolve: (val: boolean) => void = () => { };
		this.configLoaded = new Promise<boolean>(res => resolve = res);

		const config: ICustomVideoListManager["config"] | null = await this.storage.getItem("config");
		if (config) {
			this.update(state => {
				state.config = config;
				return state;
			})
		}

		resolve(true);
		this.configLoaded = true;

	}
	getDefaultListSync() {
		const id = this.get().config?.defaultList;
		return id ? this.getList(id) : undefined;
	}


	createForkedList(list: CustomVideoList, newTitle?: string) {
		return this.newList({
			title: newTitle || list.get().title + " (forked)",
			videos: list.get().videos.map(v => {
				return {
					...v,
				}
			}),
			forkedFrom: list.get().id
		})
	}

	async mergeLists(targetListId: string, sourceListId: string) {
		const targetList = await this.getAndLoadList(targetListId);
		const sourceList = await this.getAndLoadList(sourceListId);
		if (!targetList || !sourceList) {
			return;
		}
		await targetList.merge(sourceList);
	}

	async addBabelbabList(list: Partial<ICustomVideoList> = {} as any){
		const _list: ICustomVideoList = {
			id: "babelbab",
			title: "Babelbab",
			createdAt: Date.now(),
			updatedAt: Date.now(),
			videos: [],
			temporary:true,
			...list,
		}
		const listInstance = new CustomVideoList(_list, this)
		this.update(state => {
			state.lists = [
				_list,
				...state.lists,
			]
			state.listInstances = [
				listInstance,
				...state.listInstances
			]
			return state;
		})

		return listInstance
	}
}

