import { ReactiveObject } from "./ReactiveObject";
import { isHotkey } from 'is-hotkey'
import type { IDisposable } from "./commonTypes";

export enum Keys {
	ALT_J = "alt+j",
	ALT_K = "alt+k",
	ALT_L = "alt+l",
	ALT_M = "alt+m",

	J = "j",
	K = "k",
	L = "l",
	M = "m",
	H = "h",

	SPACE = "space",
	ENTER = "enter",
	ESC = "esc",

	ARROW_LEFT = "arrowleft",
	ARROW_RIGHT = "arrowright",
	ARROW_UP = "arrowup",
	ARROW_DOWN = "arrowdown",

	//modifiers
	CTRL = "ctrl",
	SHIFT = "shift",
	ALT = "alt",
}


export interface IShortcutListener {
	(e: KeyboardEvent): void;
}


export interface IShortcut {
	id: string;
	key: (Keys[] | Keys)[] | Keys;
	listeners: IShortcutListener[] | IShortcutListener;
	condition?: (e: KeyboardEvent, listener: IShortcutListener) => boolean | Promise<boolean>;
}

export interface IKeyboard {
	shortcuts: IShortcut[];
}


export class Keyboard extends ReactiveObject<IKeyboard>{

	public disposeListeners: IDisposable | undefined
	constructor() {
		super({
			shortcuts: []
		})
	}

	addShortcut(shortcut: IShortcut) {
		this.update(state => {
			state.shortcuts.push(shortcut)
			return state
		})
		this.stopListening();
		this.startListening();
	}

	addShortcuts(shortcuts: IShortcut[]) {
		this.update(state => {
			state.shortcuts.push(...shortcuts)
			return state
		})

		this.stopListening();
		this.startListening();

		return () => {
			this.removeShortcuts(shortcuts)
		}
	}

	removeShortcutsById(id: string[]) {
		this.update(state => {
			state.shortcuts = state.shortcuts.filter(s => !id.includes(s.id))
			return state
		})

		this.stopListening();
		this.startListening();
	}

	removeShortcuts(shortcuts: IShortcut[]){
		this.update(state => {
			state.shortcuts = shortcuts
			return state
		})
		this.stopListening();
		this.startListening();
	}


	startListening() {
		const shortcuts = this.get().shortcuts;

		const compiledShortcuts = shortcuts.map(shortcut => {
			let keys = Array.isArray(shortcut.key) ? shortcut.key : [shortcut.key]
			const listeners = Array.isArray(shortcut.listeners) ? shortcut.listeners : [shortcut.listeners];

			const compiledKeys = keys.map(key => {
				const k = Array.isArray(key) ? key : [key]
				const keyStr = k.join("+")
				console.log({keyStr, k})
				return {
					keyString: keyStr,
					key: key,
					isHotkey: isHotkey(keyStr)
				}
			})

			return {
				compiledKeys: compiledKeys,
				keys: keys,
				listeners: listeners,
				condition: shortcut.condition
			}
		})

		const listener = (e: KeyboardEvent) => {
			for (let i = 0; i < compiledShortcuts.length; i++) {
				const shortcut = compiledShortcuts[i];

				for (let j = 0; j < shortcut.compiledKeys.length; j++) {
					const compiledKey = shortcut.compiledKeys[j];
					if (compiledKey.isHotkey(e)) {
						for (let k = 0; k < shortcut.listeners.length; k++) {
							const listener = shortcut.listeners[k];

							if (shortcut.condition === undefined) {
								listener(e)
							} else {
								if (shortcut.condition(e, listener)) {
									listener(e)
								}
							}
						}
					}
				}
			}
		}

		window.addEventListener("keydown", listener, true)

		this.disposeListeners = {
			dispose: () => {
				window.removeEventListener("keydown", listener, true)
			}
		}

		return this.disposeListeners
	}

	stopListening() {
		if (this.disposeListeners) {
			this.disposeListeners.dispose()
		}
	}
}