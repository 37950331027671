import App from './App.svelte'
import './index.css';
import './scrollbar.css'
import 'virtual:windi.css'

const app = new App({
	target: document.body,
})

export default app
