<script lang="ts">
	export let delay: number = 0;
	export let duration: number = 1.2;
</script>

<div
	style="
		--duration:{duration}s;
		--delay:{delay}s;
	"
	class="h-full skeleton w-full animate-pulse bg-neutral-focus"
/>

<style lang="postcss">
	.skeleton {
		animation-duration: var(--duration);
		animation-delay: var(--delay);
	}
</style>
