<script lang="ts">
  export let error: Error;
  export let minimal: boolean = true;

  // $: console.error(error);
</script>

<div class="text-error-content">
  <div class="text-2xl">
    {error.message}
  </div>

  {#if !minimal}
    <div class="text-sm">
      {error.stack}
    </div>
  {/if}
</div>
