import { overlayModal } from '@common/basicStores';
import type { IDisposable } from '@common/commonTypes';
import type { IVideoMeta } from '@common/invidiousApi';
import type { Props } from '@common/svelteUtils';
import type { SvelteComponentTyped } from 'svelte';


export const openModal = async <
	T extends {default: typeof SvelteComponentTyped<any, any, any>},
>(
	component: () => Promise<T>,
	props?: ConstructorParameters<T["default"]>["0"]["props"],
): Promise<IDisposable> => {

	const promise = component();
	overlayModal.open(promise, props);
	return {
		dispose: () => overlayModal.close(),
	}
}



export const openCustomListViewModal = async (opts: {
	listId: string;
	inlineMode?: boolean;
	cancelButtonVisibility?: boolean;
	listButtonVisibility?: boolean
}) => {
	const c = await openModal(
		() => import("./CustomListViewModal.svelte"),
		{
			listId: opts.listId,
			inlineMode: opts.inlineMode || false,
			cancelButtonVisibility: opts.cancelButtonVisibility || false, 
			listButtonVisibility: opts.listButtonVisibility || true
		}
	)

	return c
}



export const openScannerModal = (opts: {
	onDone: () => void;
	onClose: () => void;
}) => {
	return openModal(
		() => import("./ScannerModal.svelte"),
		{
			onDone: opts.onDone,
			onClose: opts.onClose,
		}
	)
}


export const openConnectionManagerModal = () => {
	return openModal(
		() => import("./ConnectionManagerModal.svelte"),
	)
}

export const openVideoDownloadModal = (videoMeta: IVideoMeta) => {
	return openModal(
		() => import("./VideoDownloadModal.svelte"),
		{
			videoMeta,
		}
	)
}


export const transparentOverlaySearch = (opts: {
	query: string;
	onClose: (query: string) => void;
	onSearch: (query: string) => void;
}) => {
	return openModal(
		() => import("./TransparentOverlaySearch.svelte"),
		{
			query: opts.query,
			onClose: opts.onClose,
			onSearch: opts.onSearch,
		}
	)
}
