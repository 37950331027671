import type { IVideoMeta } from "./invidiousApi";
import { ReactiveObject } from "./ReactiveObject";


export interface ILocalCast {
	videoMeta?: IVideoMeta;
	currentTime?: number;
	volume?: number;
	isPaused?: boolean;
}

export class LocalCast extends ReactiveObject<ILocalCast>{
	constructor(options: Partial<ILocalCast> = {} as any) {
		const _options: ILocalCast = {
			...options
		}
		super(_options);
	}
}