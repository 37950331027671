<script>
import SkeletonAnimation from "./SkeletonAnimation.svelte";

</script>

<div class="flex flex-row gap-2">
	{#each new Array(4).fill(null) as _, i}
		<span class="inline-block h-12 w-12 rounded-full overflow-hidden">
			<SkeletonAnimation />
		</span>
	{/each}
</div>