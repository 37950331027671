
import { ExtendedWritable } from "./ExtendedWritable";
import type { BeforeInstallPromptEvent } from '@ServiceWorker/commonWorkerUtils';
import { SW_BROADCAST_CHANNEL_KEY } from "../constants";
import { UiThemes } from "./styles";
import type {SponsorBlock} from 'sponsorblock-api'
import { derived } from "svelte/store";
import type { VideoJsPlayer } from "video.js";
import { CustomVideoListManager } from "./customVideoList";
import { OverlayModalController } from "./OverlayModalContainer";
import checkIsMobile from 'is-mobile'
import type { IVideoItem, IVideoMeta } from "./invidiousApi";
import { CastApi } from "./cast";
import { SnackbarController } from "./snackbarController";
import { ScrollPositionManager } from "./scrollPosition";
import { MemoryCache } from "./memoryCache";
import type { IMember } from "./roomStore";
import { ConnectionManager } from "./ConnectionManager";
import { LaptopCast } from "./LaptopCast";
import { ReactiveObject } from "./ReactiveObject";
import type { ISectionView } from "@components/VideoList/commonTypes";
import { LocalCast } from "./localCast";
import { Networks } from "./networks";
import { OverlayComponentManager } from "./overlayComponents";
import { Keyboard } from "./keyboard";


export const deferredInstallPrompt = new ExtendedWritable<BeforeInstallPromptEvent>();
export const swRegistration = new ExtendedWritable<ServiceWorkerRegistration | undefined>();
export const rootElement = new ExtendedWritable<HTMLElement | undefined>();
export const theme = new ExtendedWritable<UiThemes>(UiThemes.black);

export const sponsorBlock = new ExtendedWritable<SponsorBlock | undefined>()

export const presentationRequest = new ExtendedWritable<any | undefined>(undefined);

export const availablePresentationDevices = new ExtendedWritable<any[]>([]);
export const presentationApiAvailable = new ExtendedWritable<boolean>(false);
export const presentationConnection = new ExtendedWritable<any | undefined>(undefined);
export const receiverReadyToSendMessages = new ExtendedWritable<boolean>(false);
export const videoPlayerInstance = new ExtendedWritable<VideoJsPlayer | undefined>(undefined);
export const customVideoListManager =new CustomVideoListManager();

export const overlayModal = new OverlayModalController({
	isOpen: false,
	component: null,
	props: undefined,
})

export enum Orientation{
	LANDSCAPE = "landscape",
	PORTRAIT = "portrait",
}

export let orientation = new ExtendedWritable<Orientation>(Orientation.PORTRAIT);
export let isMobile = new ExtendedWritable<boolean>(checkIsMobile());
export let currentVideoMeta = new ExtendedWritable<IVideoMeta | undefined>(undefined);
export let castApi = new CastApi({
	isInitialized: false,
})
export const snackbarController = new SnackbarController()
export const scrollPositionManager = new ScrollPositionManager();
export const currentChannelView = new ExtendedWritable<{
	id: string;
	active: boolean;
} | undefined>(undefined);

export const inlineSearchActive = new ExtendedWritable<boolean>(false);
export const showCurrentVideoRecommendations = new ExtendedWritable<boolean>(false);

export const videoItemCache = new MemoryCache<IVideoItem>([], "videoId");

export const currentUser = new ExtendedWritable<Partial<IMember> | undefined>()

export const selectedDevices = new ExtendedWritable<{
	audioInput?: MediaDeviceInfo,
	videoInput?: MediaDeviceInfo,
	audioOutput?: MediaDeviceInfo,
}>({})


export const connectionManager = new ConnectionManager()
export const laptopCast = new LaptopCast()


export interface IDisplayStats{
	recommendations: {
		scrollContainer: {
			height?: number;
			width?: number;
			element?: HTMLElement
		}
	}
}

export const displayStats = new ReactiveObject<IDisplayStats>({
	recommendations: {
		scrollContainer: {
		}
	}
})


export let sectionListCache = new ExtendedWritable<{
	[key: number]: ISectionView[]
}>({})

export const localCast = new LocalCast();
export const backgroundPlay = new ExtendedWritable<boolean>(false);
export const sleepMode = new ExtendedWritable<{
	time: 30 | 60 | 90 | 0;
	enabled: boolean;
}>({
	time: 0,
	enabled: false,
});

export const isOffline = new ExtendedWritable<boolean>(!navigator.onLine);

export const networks = new Networks({})

export const overlayComponentManager = new OverlayComponentManager();
export const preventNextSeekEvent = new ExtendedWritable<boolean>(false);

export const videoMetaLoading = new ExtendedWritable<boolean>(false);

export const keyboard = new Keyboard();

export const rootVirtualList = new ExtendedWritable<{
	resize: () => void;
	containerElement: HTMLElement | null
} | undefined>(undefined);