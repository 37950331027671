

export let clearOpaqueResponsesFromCache = async (cacheName: string) => {
	  let cache = await caches.open(cacheName);

	  let keys = await cache.keys();
	  const responses = await Promise.all(keys.map(key => cache.match(key)));
	  
	  for(let i = 0; i < responses.length; i++) {
	    let response = responses[i];
	    if(response?.type === "opaque"){
	      await cache.delete(keys[i]);
		}
	  }

	  return;
}


export const clearCache = async (cacheName: string) => {
	if(await caches.has(cacheName)){
		await caches.delete(cacheName);
	}
}