<script lang="ts">
	import LazyLoad from "@components/LazyLoad/LazyLoad.svelte";
	import type { SvelteComponentTyped } from "svelte";

	
	interface IModule {
		id: string;
		component: () => Promise<{
			default: typeof SvelteComponentTyped<any, any, any>;
		}>;
		props?: Record<string, any>;
		condition?: (module: IModule) => boolean;
	}

	const isRouteMinimalLoad: () => boolean = () => {
		const pathname = window.location.pathname;
		return ["/embed", "/embed-player"].some((p) => pathname.startsWith(p));
	};
	
	const modules: IModule[] = [
		{
			id: "internetConnectionState",
			component: () => import("./InternetConnectionState.svelte"),
		},
		{
			id: "serviceWorker",
			component: () => import("./../ServiceWorker.svelte"),
		},
		{
			id: "laterList",
			component: () => import("./LaterList.svelte"),
			condition: () => !isRouteMinimalLoad(),
		},
		{
			id: "urlActions",
			component: () => import("./UrlActions.svelte"),
		},
		{
			id: "orientationChange",
			component: () => import("./OrientationChange.svelte"),
		},
		{
			id: "desktopView",
			component: () => import("./DesktopView.svelte"),
		},
		{
			id: "loadNetworkManager",
			component: () => import("./LoadNetworkManager.svelte"),
		},
		{
			id: "snackbar",
			component: () => import("./../Snackbar/Snackbar.svelte"),
		},
		{
			id: "keyboardInitializer",
			component: () => import("@components/Utils/KeyboardInitializer.svelte"),
		},
	];
</script>

{#each modules as module (module.id)}
	{#if !module.condition || module.condition(module)}
		<!-- {module.id} -->
		<LazyLoad let:component loader={module.component} hideError hideLoader>
			<svelte:component
				this={component}
				{...module?.props ? module.props : {}}
			/>
		</LazyLoad>
	{/if}
{/each}
