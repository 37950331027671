<script>
	import InfoSkeleton from "./InfoSkeleton.svelte";
import ListSkeleton from "./ListSkeleton.svelte";
import ToolbarSkeleton from "./ToolbarSkeleton.svelte";
import VideoSkeleton from "./VideoSkeleton.svelte";
</script>
<div class="h-full w-full flex flex-col md:flex-row gap-5 md:p-5">
	<div class="flex flex-col md:w-4/6 gap-5">
		<div class="md:h-4/5 overflow-hidden">
			<VideoSkeleton />
		</div>
		<div class="hidden h-1/5 md:flex flex-col gap-5">	
			<InfoSkeleton />
			<ToolbarSkeleton />
		</div>
	</div>
	<div class="px-2 flex flex-col gap-5 md:w-2/6">
		<div class="w-full md:hidden flex flex-col gap-5">
			<InfoSkeleton />
			<ToolbarSkeleton />
		</div>
		<div class="flex-grow relative">
			<div class="absolute inset-0">
				<ListSkeleton />
			</div>
		</div>
	</div>
</div>