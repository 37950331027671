import { writable, type Writable, get } from 'svelte/store'

export interface IExtendedWritableEvents{
	[key: string]: {
		[key: string]: any
	}
}

export class ExtendedWritable<T, U extends IExtendedWritableEvents = any> extends EventTarget implements Writable<T> {

	public set: Writable<T>['set']
	public update: Writable<T>['update']
	public subscribe: Writable<T>['subscribe']


	constructor(...args: Parameters<typeof writable>) {
		super()
		const store:any = writable(...args);
		this.set = (...args) => store.set(...args)
		this.update = (...args) => store.update(...args)
		this.subscribe = (...args) => store.subscribe(...args)
	}

	get(): T {
		return get(this)
	}

	dispatch<K extends keyof U>(
		eventName: K,
		detail: U[K]
	){
		this.dispatchEvent(new CustomEvent(eventName as string, {
			detail
		}))
	}

	addListener<
		K extends keyof U,
	>(
		eventName: K,
		handler: (event: CustomEvent<U[K]>) => void
	): () => void{
		this.addEventListener(eventName as string, handler as any)
		return () => this.removeEventListener(eventName as string, handler as any)
	}
}
