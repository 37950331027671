import { uid } from "uid";
import { ReactiveObject } from "./ReactiveObject";


export interface IScrollPosition{
	x: number;
	y: number;
	url: string;
	id: string;
}

export interface IScrollPositionManager {
	positions: IScrollPosition[]
	currentScrollContainer?: HTMLElement;
}

export class ScrollPositionManager extends ReactiveObject<IScrollPositionManager>{
	constructor(){
		super({
			positions: []
		})
	}
	saveCurrentPosition(){
		const scrollContainer = this.get().currentScrollContainer;
		if(!scrollContainer) return;

		const href = window.location.href;
		this.removePosition(href);

		const scrollPosition = {
			x: scrollContainer.scrollLeft,
			y: scrollContainer.scrollTop,
			url: window.location.href,
			id: uid()
		}

		this.update(state => {
			state.positions.push(scrollPosition);
			return state;
		})
	}


	getCurrentPosition(){
		const href = window.location.href;
		const position = this.get().positions.find(p => p.url === href);
		return position;
	}

	findPosition(href: string){
		return this.get().positions.find(p => p.url === href);
	}

	removePosition(href: string){
		const position = this.findPosition(href);
		if(!position) return;
		
		this.update(state => {
			state.positions = state.positions.filter(p => p.url !== href);
			return state;
		})
	}

	gotoPosition(position: IScrollPosition){
		const scrollContainer = this.get().currentScrollContainer;
		if(!scrollContainer) return;
		scrollContainer.scrollTo(position.x, position.y);
	}

	gotoCurrentPosition(){
		const position = this.getCurrentPosition();
		if(!position) return;
		this.gotoPosition(position);
	}
}