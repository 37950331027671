import type { IVideoItem as ILocalVideoItem } from './../components/VideoList/commonTypes';
import { getCachedVideoMeta } from './cacheVideo';
export const baseUrl = "https://vid.puffyan.us/";
export const invidiousUrls = [
	"https://invidious.xn--lkv.com",
	"https://vid.puffyan.us/",
	"https://yt.artemislena.eu",
	"https://invidious.projectsegfau.lt/",
	"https://invidious.nerdvpn.de/",
	"https://y.com.sb",
	"https://yt.artemislena.eu",
	"https://youtube.076.ne.jp/",
	"https://invidious.nerdvpn.de/",
	"https://invidious.slipfox.xyz/",
	"https://inv.vern.cc/",
	"https://inv.bp.projectsegfau.lt/",
	"https://invidious.tiekoetter.com/",
	"https://invidious.sethforprivacy.com/"
]

export interface IVideoItem {

	"title": string,
	"videoId": string,
	"videoThumbnails": {
		"quality": string,
		"url": string,
		"width": number,
		"height": number
	}[]

	"lengthSeconds": number,
	"viewCount": number,
	"viewCountText": string,

	"author": string,
	"authorId": string,
	"authorUrl": string,

	"published": number,
	"publishedText": string,
	"description": string,
	"descriptionHtml": string,

	"liveNow": boolean,
	"paid": boolean,
	"premium": boolean
}

export interface ITrendingItem {

	"title": string,
	"videoId": string,
	"videoThumbnails": [
		{
			"quality": string,
			"url": string,
			"width": number,
			"height": number
		}
	],

	"lengthSeconds": number,
	"viewCount": number,

	"author": string,
	"authorId": string,
	"authorUrl": string,

	"published": number,
	"publishedText": string,
	"description": string,
	"descriptionHtml": string,

	"liveNow": boolean,
	"paid": boolean,
	"premium": boolean

}


export interface IVideoThumbnail {


	quality: string,
	url: string,
	width: number,
	height: number

}


export interface IChannelVideo {
	title: string,
	videoId: string,
	author: string,
	authorId: string,
	authorUrl: string,

	videoThumbnails: IVideoThumbnail[],
	description: string,
	descriptionHtml: string,

	viewCount: number,
	published: number,
	publishedText: string,
	lengthSeconds: number
	paid: boolean,
	premium: boolean
}


export interface IChannelMeta {
	"author": string,
	"authorId": string,
	"authorUrl": string,
	"authorBanners": ({
		"url": string,
		"width": number,
		"height": number
	})[],
	"authorThumbnails": {
		"url": string,
		"width": number,
		"height": number
	}[],

	"subCount": number,
	"totalViews": number,
	"joined": number,

	"paid": boolean,
	"autoGenerated": boolean,
	"isFamilyFriendly": boolean,
	"description": string,
	"descriptionHtml": string,
	"allowedRegions": string[],

	"latestVideos": IVideoItem[],
	"relatedChannels": {
		"author": string,
		"authorId": string,
		"authorUrl": string,
		"authorThumbnails": {
			"url": string,
			"width": number,
			"height": number
		}[]
	}[]
}






export const getTrending = async (): Promise<ITrendingItem[]> => {
	const url = new URL("/api/v1/trending", baseUrl);
	const res = await fetchInvidious(url.toString());
	return res;
}


export const getChannelVideos = async (channelId: string, options?: IFetchInvidiousOptions): Promise<IChannelVideo[]> => {
	const url = new URL(`/api/v1/channels/videos/${channelId}`, baseUrl);
	return await fetchInvidious(url.href, options);
}


export const getChannelMeta = async (channelId: string, options?: IFetchInvidiousOptions): Promise<IChannelMeta> => {
	const url = new URL(`/api/v1/channels/${channelId}`, baseUrl);
	return await fetchInvidious(url.href, options);
}
export const inMemoryVideoMetaCache = new Map<string, IVideoMeta>();
export interface IVideoMeta {
	
	"title": string,
	"videoId": string,
	"videoThumbnails": IVideoThumbnail[],

	"description": string,
	"descriptionHtml": string,
	"published": number,
	"publishedText": string,

	"keywords": string[],
	"viewCount": number,
	"likeCount": number,
	"dislikeCount": number,

	"paid": boolean,
	"premium": boolean,
	"isFamilyFriendly": boolean,
	"allowedRegions": string[],
	"genre": string,
	"genreUrl": string,

	"author": string,
	"authorId": string,
	"authorUrl": string,
	"authorThumbnails": {
		"url": string,
		"width": number,
		"height": number
	}[],

	"subCountText": string,
	"lengthSeconds": number,
	"allowRatings": boolean,
	"rating": number,
	"isListed": boolean,
	"liveNow": boolean,
	"isUpcoming": boolean,
	"premiereTimestamp"?: number,

	"hlsUrl"?: string,
	"adaptiveFormats": {
		"index": string,
		"bitrate": string,
		"init": string,
		"url": string,
		"itag": string,
		"type": string,
		"clen": string,
		"lmt": string,
		"projectionType": number,
		"container": string,
		"encoding": string,
		"qualityLabel"?: string,
		"resolution"?: string
	}[],
	"formatStreams": {
		"url": string,
		"itag": string,
		"type": string,
		"quality": string,
		"container": string,
		"encoding": string,
		"qualityLabel": string,
		"resolution": string,
		"size": string
	}[],
	"captions": {
		"label": string,
		"languageCode": string,
		"url": string
	}[],
	"recommendedVideos": IVideoItem[];
	"isCachedResponse"?: boolean;
}


export interface IVideoInfo {
	title: string;
	videoId: string;
	author: string;
	authorId: string;
}

export interface ISearchResponseVideo {
	type: "video",
	title: string,
	videoId: string,
	author: string,
	authorId: string,
	authorUrl: string,
	videoThumbnails: ({
		quality: string,
		url: string,
		width: number,
		height: number
	})[],
	description: string,
	descriptionHtml: string,
	viewCount: number,
	published: number,
	publishedText: string,
	lengthSeconds: number,
	liveNow: boolean,
	paid: boolean,
	premium: boolean
}

export type ISearchResponse = ISearchResponseVideo[];


export interface IFetchInvidiousOptions {
	invidiousInstances: string[],
	lastWorkingInstanceIndex: number
	setWorkingInstance: (instanceIndex: number, instances: string[]) => void,
	getFromCache?: (url: string) => Promise<Response | null>
	putInCache?: (url: string, data: any) => void
	startedFetch?: (url: string) => void
	finishedFetch?: (url: string) => void
	fetchError?: (url: string, error: any) => void
}

const fetchInvidious = async (url: string, options?: IFetchInvidiousOptions): Promise<any> => {
	let invidiousUrlIndex = options?.lastWorkingInstanceIndex || 0;
	let response: Response | null = null;
	let json: any | null = null;
	let instances: string[] = options?.invidiousInstances || invidiousUrls;


	while (invidiousUrlIndex < instances.length) {
		const invidiousUrl = instances[invidiousUrlIndex];

		try {
			const tempUrl = new URL(url);
			const newUrl = new URL(`${tempUrl.pathname}${tempUrl.search}`, invidiousUrl);

			const cacheResponse = await options?.getFromCache?.(
				`${tempUrl.pathname}${tempUrl.search}`,
			);


			if(options?.startedFetch){
				options.startedFetch(newUrl.href);
			}


			response = cacheResponse || await fetch(newUrl.href);

			if(options?.finishedFetch){
				options.finishedFetch(newUrl.href);
			}
			if (response.status == 200) {
				options?.setWorkingInstance?.(invidiousUrlIndex, instances)
				json = await response.json();

				if (!json) {
					throw new Error("No json");
				}

				if (json.error) {
					throw new Error(json.error);
				}

				if (options?.putInCache) {
					options.putInCache(`${tempUrl.pathname}${tempUrl.search}`, json);
				}
				break;
			}else{
				throw new Error("Status code not 200");
			}
		} catch (err) {
			if(options?.fetchError){
				options.fetchError(url, err);
			}
			console.error(`Error fetching invidious - ${invidiousUrl}`, err);
			response = null
		}


		if (invidiousUrlIndex == instances.length - 1) {
			invidiousUrlIndex = 0;
		} else {
			invidiousUrlIndex++;
		}

		if (invidiousUrlIndex === (options?.lastWorkingInstanceIndex || 0)) {
			break;
		}
	}

	if (!json || json.error) {
		throw new Error(json?.error || "No json");
	}


	return json;
}


export const getVideoMeta = async (videoId: string, options?: IFetchInvidiousOptions, useCache: boolean = true): Promise<IVideoMeta> => {

	if(useCache && inMemoryVideoMetaCache.has(videoId)) {
		return inMemoryVideoMetaCache.get(videoId) as any;
	}
	if(useCache){
		try{
			const resCached = await getCachedVideoMeta(videoId);
			if(resCached) {
				return resCached;
			}
		}catch(err){
			console.error(`Error getting cached video meta`, err);
		}
	}

	const url = new URL(`/api/v1/videos/${videoId}`, baseUrl);
	const res = await fetchInvidious(url.toString(), options);

	if(useCache){
		inMemoryVideoMetaCache.set(videoId, res);
	}

	return res as any;
}

export const searchResults = (query: string, options?: IFetchInvidiousOptions): Promise<ISearchResponse> => {
	const url = new URL(`/api/v1/search`, baseUrl);
	url.searchParams.set("q", query);
	return fetchInvidious(url.toString(), options);
}


export const getRecommendationUrl = (
	videoMeta: IVideoMeta["recommendedVideos"]["0"]
): string => {
	const url = new URL(
		`/watch?v=${videoMeta.videoId}`,
		window.location.origin
	);
	return url.href;
};

export const getWatchUrl = (
	videoId: string
): string => {
	const url = new URL(
		`/watch?v=${videoId}`,
		window.location.origin
	);
	return url.href;
};

export const getDefaultThumbnail = (
	thumbnails: IVideoThumbnail[] | undefined,
	{
		forceHTTPS = false,
	}: {
		forceHTTPS: boolean
	} = {} as any
): IVideoMeta["videoThumbnails"][number] | undefined => {

	if (!thumbnails?.length) {
		return
	}
	const thumbnail = (
		thumbnails.find(
			(thumbnail) => thumbnail?.quality === "maxres"
		)
	) || thumbnails[0];

	if (!thumbnail) {
		return
	}
	const urlInstance = new URL(thumbnail.url, baseUrl);
	if (forceHTTPS) {
		urlInstance.protocol = "https:";
	}
	return {
		url: urlInstance.href,
		width: thumbnail.width,
		height: thumbnail.height,
		quality: thumbnail.quality,
	};
};


export const getThumbnailBasedOnScreenSize = ({
	thumbnails,
	screenHeight,
	screenWidth
}: {
	thumbnails: IVideoMeta["videoThumbnails"],
	screenWidth: number,
	screenHeight: number,
}) => {

	const idealThumbnail = thumbnails.map((thumbnail) => {
		const diffWidth = Math.abs(thumbnail.width - screenWidth);
		const diffHeight = Math.abs(thumbnail.height - screenHeight);
		return {
			...thumbnail,
			diffWidth,
			diffHeight,
		};
	})
		.sort((a, b) => {
			if (a.diffWidth < b.diffWidth) {
				return -1;
			}
			if (a.diffWidth > b.diffWidth) {
				return 1;
			}
			if (a.diffHeight < b.diffHeight) {
				return -1;
			}
			if (a.diffHeight > b.diffHeight) {
				return 1;
			}
			return 0;
		})[0]


	let t = {
		...idealThumbnail,
		url: new URL(idealThumbnail.url, baseUrl).href
	}

	return t
}


export const getIdealFormatStream = (streams: IVideoMeta["formatStreams"]) => {

	const s = streams.map(item => {
		const resolution = +(item.resolution.replace("p", ""));
		return {
			item,
			resolution,
		}
	}).sort((a, b) => {
		// sort decending
		if (a.resolution < b.resolution) {
			return 1;
		}
		if (a.resolution > b.resolution) {
			return -1;
		}
		return 0;
	}).map(item => item.item);

	const idealStream = s.find((stream) => {
		return stream.container === "mp4" && stream.resolution === "720p";
	})
	return idealStream || s[0];
}

export const getChannelId = (authorUrl: string) => {
	return authorUrl?.split("/").pop() || "";
}


export const localToInvidiousVideoItem = (videoItem: ILocalVideoItem): IVideoItem => {
	return {
		videoId: videoItem.videoId,
		title: videoItem.title,
		description: "",
		author: videoItem.author,
		authorUrl: videoItem.authorUrl,
		videoThumbnails: [videoItem.thumbnail],
		published: 0,
		viewCountText: videoItem.viewCountText || "",
		viewCount: 0,
		descriptionHtml: "",
		liveNow: false,
		authorId: videoItem.authorId,
		premium: false,
		paid: false,
		lengthSeconds: 0,
		publishedText: ""
	}
}


export const invidiousToLocalVideoItem = (v: IVideoItem | ISearchResponseVideo): ILocalVideoItem => {
	return {
		title: v.title,
		videoUrl: getWatchUrl(v.videoId),
		thumbnail: getDefaultThumbnail(v.videoThumbnails as any) || {
			url: "",
			width: 0,
			height: 0,
			quality: "",
		},
		author: v.author,
		authorUrl: v.authorUrl,
		viewCountText: (v as any).viewCountText as any || "",
		authorId: v.authorUrl?.split("/").pop() || "",
		videoId: v.videoId,
	}
}