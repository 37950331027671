import { getDefaultThumbnail, getIdealFormatStream, type IVideoMeta } from "./invidiousApi";
import { ReactiveObject } from "./ReactiveObject";

export let cast: typeof window["cast"];
export interface ICastApiOptions {
	isInitialized: boolean;
	connected: boolean;
	currentVideoId?: string;
	volume?: number;
}

export interface ICastVideo {
	id: string
	src: string;
	title?: string;
	poster?: string;
	description?: string;
	contentType: string
	subtitles: {
		active: boolean,
		label: string,
		src: string,
	}[]
}

const waitUntilCastIsDefined = () => new Promise<void>(resolve => {
	const interval = setInterval(() => {
		if (window.cast) {
			clearInterval(interval);
			resolve();
		}else{
			console.log("cast is not defined yet");
		}
	}, 500);
});

export class CastApi extends ReactiveObject<ICastApiOptions>{
	constructor(options: Partial<ICastApiOptions> = {} as any) {
		const _options: ICastApiOptions = {
			isInitialized: false,
			connected: false,
			volume: 1,
			...options
		}
		super(_options);
	}

	async init() {
		if (window.castApiAvailable) {
			await waitUntilCastIsDefined();
			cast = window.cast
			this.initializeApi()
		} else {
			window['__onGCastApiAvailable'] = async (isAvailable) => {
				if (isAvailable) {
					await waitUntilCastIsDefined();
					cast = window.cast
					this.initializeApi();
				} else {
					console.error("Cast API not available");
				}
			};
		}
	}

	initializeApi() {
		this.setItem("isInitialized", true)
		cast.framework.CastContext.getInstance().setOptions({
			receiverApplicationId: chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID,
			autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED
		});
		
		const session = this.getCurrentSession();

		if(session){
			this.proxy.connected = true
		}
	}


	getCurrentSession() {
		return cast.framework.CastContext.getInstance().getCurrentSession();
	}

	async connect() {
		await cast.framework.CastContext.getInstance().requestSession()
		this.proxy.connected = true
	}


	async castVideo(opts: ICastVideo) {

		try {
			if (!this.proxy.connected) {
				await this.connect();
			}

			const mediaInfo = new chrome.cast.media.MediaInfo(opts.src, opts.contentType);
			const request = new chrome.cast.media.LoadRequest(mediaInfo);
			
			const castSession = this.getCurrentSession();

			if (castSession) {
				await castSession.loadMedia(request)
				console.log("success")
				this.proxy.currentVideoId = opts.id
			}else{
				throw new Error("No Cast Session available")
			}
		} catch (err) {
			throw err;
		}
	}

	async castYoutubeVideo(video: IVideoMeta){
		let stream = getIdealFormatStream(video.formatStreams)
		if(!stream){
			return;
		}else{
			await this.castVideo({
				id: video.videoId,
				src: stream?.url,
				title: video.title,
				poster: getDefaultThumbnail(video.videoThumbnails)?.url,
				description: video.description,
				contentType: stream?.type,
				subtitles: []
			})
		}
	}

	disconnect(){
		const session = this.getCurrentSession();
		session?.endSession(true);
		this.proxy.connected = false;
	}

	getMediaSession(){
		const session = this.getCurrentSession();
		return session?.getMediaSession();
	}

	async seek(val: number){
		const session = this.getMediaSession();
		const seekRequest = new chrome.cast.media.SeekRequest();
		seekRequest.currentTime = val;
		
		await new Promise((res,rej) => {
			session?.seek(seekRequest, () => {
				res(true)
			} , rej)
		})
	}

	getCurrentTime(){
		const session = this.getMediaSession();
		return session?.getEstimatedTime()
	}



	async seekForward(val: number){
		const time = this.getCurrentTime();

		if(time){
			const newTime = time + val;
			await this.seek(newTime)
		}
	}

	async seekBackward(val: number){
		const time = this.getCurrentTime();
		if(time){
			const newTime = time - val;
			await this.seek(newTime)
		}
	}

	async getStatus(){
		const session = this.getMediaSession();
		const statusRequest = new chrome.cast.media.GetStatusRequest();
		const status = await new Promise((res,rej) => {
			session?.getStatus(statusRequest, res, rej)
		})
		return status
	}

	async play(){
		const session = this.getMediaSession();
		const playRequest = new chrome.cast.media.PlayRequest();
		await new Promise((res,rej) => {
			session?.play(playRequest, res, rej)
		})
	}

	async pause(){
		const session = this.getMediaSession();
		const pauseRequest = new chrome.cast.media.PauseRequest();
		await new Promise((res,rej) => {
			session?.pause(pauseRequest, res, rej)
		})
	}

	async setVolume(level: number, muted: boolean){
		const session = this.getMediaSession();
		const volume = new chrome.cast.Volume(level, muted);
		const volumeRequest = new chrome.cast.media.VolumeRequest(volume);
		await new Promise((res,rej) => {
			session?.setVolume(volumeRequest, res, rej)
		})
	}

	getVolume(){
		const session = this.getMediaSession();
		return session?.volume
	}

	async increaseVolumeByStep(step: number = 0.2){
		const vol = this.proxy.volume || 1;
		const newVol = vol + step;

		if(newVol > 1){	
			return;
		}
		this.setItem("volume", newVol)
		await this.setVolume(newVol, false)
	}

	async decreaseVolumeByStep(step: number = 0.2){
		const vol = this.proxy.volume || 1;
		const newVol = vol - step;

		if(newVol < 0){	
			return;
		}
		this.setItem("volume", newVol)
		await this.setVolume(newVol, false)
	}
}