import type { SvelteComponentTyped } from "svelte";
import { uid } from "uid";
import { ReactiveObject } from "./ReactiveObject";



export enum SnackbarTypes{
	SUCCESS = "SUCCESS",
	ERROR = "ERROR",
	WARNING = "WARNING",
	INFO = "INFO"
}

export interface ISnackbarAction{
	label?: string;
	class?: string;
	onClick?: () => void;
	component?: SvelteComponentTyped;
	icon?: SvelteComponentTyped
	getCloseMethod?: (method: () => void) => void;
}

export interface ISnackbar{
	id: string;
	message: string;
	type?: SnackbarTypes;
	duration?: number;
	component?: typeof SvelteComponentTyped;
	icon?: typeof SvelteComponentTyped
	actions?: ISnackbarAction[];
	class?: string;
}
export interface ISnackbarControllerOptions{
	queue: ISnackbar[];
}

export class SnackbarController extends ReactiveObject<ISnackbarControllerOptions>{
	constructor(options: Partial<ISnackbarControllerOptions> = {} as any){
		const _options:ISnackbarControllerOptions = {
			queue: [],
			...options
		}
		super(_options)
	}
	add(snackbar: Partial<ISnackbar>){
		const defaultOptions: ISnackbar = {
			id: uid(),
			message: "",
			...snackbar
		}
		this.update((state) => {
			state.queue.push({
				...defaultOptions,
				...snackbar
			})
			return state
		})
	}
	
	clear(){
		this.update((state) => {
			state.queue = []
			return state
		})
	}

	updateSnackbar(id: string, callback: (snackbar: ISnackbar) => ISnackbar){
		this.update((state) => {
			const index = state.queue.findIndex(snackbar => snackbar.id === id);
			if(index !== -1){
				state.queue[index] = callback(state.queue[index]);
			}
			return state;
		})
	}
}