<script lang="ts">
import SkeletonAnimation from "./SkeletonAnimation.svelte";

	export let delay: number = 0;
	export let duration: number = 1.2;

</script>
<div class="w-full">
	<div class="w-full md:flex hidden  flex-row gap-5">
		<div class="aspect-video rounded-lg h-36 overflow-hidden">
			<SkeletonAnimation {delay} {duration}/>
		</div>
		<div class="flex flex-col gap-2 w-full">
			<div class="h-8 w-3/4">
				<SkeletonAnimation {delay} {duration}/>
			</div>
			<div class="h-8 w-1/3">
				<SkeletonAnimation {delay} {duration}/>
			</div>
			<div class="h-8 w-1/3">
				<SkeletonAnimation {delay} {duration}/>
			</div>
		</div>
	</div>
	<div class="w-full md:hidden flex flex-col gap-2">
		<div class="aspect-video rounded-lg max-h-36 overflow-hidden">
			<SkeletonAnimation {delay} {duration} />
		</div>
		<div class="h-8 w-4/5">
			<SkeletonAnimation {delay} {duration}/>
		</div>
	</div>
</div>