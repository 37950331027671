<script lang="ts">
  import LazyLoad from "@components/LazyLoad/LazyLoad.svelte";
  import WatchPageSkeleton from "@components/Skeleton/WatchPageSkeleton.svelte";
  import PreserveScrollPosition from "@components/Utils/PreserveScrollPosition.svelte";
  import type { SvelteComponentTyped } from "svelte";
  import { Route, Router } from "svelte-navigator";

  interface IRoute {
    path: string;
    component: () => Promise<any>;
    routeProps?: {
      [key: string]: any;
    };
    componentProps?: {
      [key: string]: any;
    };

    loadingComponent?: any;
  }

  const routes: IRoute[] = [
    {
      path: "/",
      component: () => import("./Root.svelte"),
    },
    {
      path: "/watch",
      component: () => import("./Video.svelte"),
      loadingComponent: WatchPageSkeleton,
    },
    {
      path: "/channel/:channelId",
      component: () => import("./Channel.svelte"),
    },

    {
      path: "/channel/:channelId",
      component: () => import("./Channel.svelte"),
    },

    {
      path: "/channel/:channelId/:channelSection",
      component: () => import("./Channel.svelte"),
    },

    {
      path: "/results",
      component: () => import("./Results.svelte"),
    },
    {
      path: "/share-target",
      component: () => import("./ShareTarget.svelte"),
    },
    {
      path: "/trending",
      component: () => import("./Trending.svelte"),
    },
    {
      path: "/connect",
      component: () => import("./Connect.svelte"),
    },
    {
      path: "/scan",
      component: () => import("./Scan.svelte"),
    },
    {
        path: "/localcast",
        component: () => import("./LocalCast.svelte"),
    },
     {
        path: "/embed/:id",
        component: () => import("./Embed.svelte"),
    },
    { 
      path: "/lists",
      component: () => import("./Lists.svelte"),
    },
    { 
      path: "/lists/:listId",
      component: () => import("./Lists.svelte"),
    },

    { 
      path: "/embed-connect",
      component: () => import("./EmbedConnect.svelte"),
    },
    { 
      path: "/radar",
      component: () => import("./Radar.svelte"),
    },

     { 
      path: "/embed-player/:videoId",
      component: () => import("./EmbedPlayer.svelte"),
    },
    {
      path: "*",
      component: () => import("./DefaultRouteHandler.svelte"),
    },
  ];
</script>

<Router>
  <PreserveScrollPosition />
  {#each routes as route}
    <Route
      let:params
      let:location
      let:navigate
      path={route.path}
      {...route.routeProps ?? {}}
    >
      <LazyLoad
        let:component
        loader={route.component}
        loadingComponent={route.loadingComponent}
      >
        <svelte:component
          this={component}
          {params}
          {location}
          {navigate}
          {...route.componentProps ?? {}}
        />
      </LazyLoad>
    </Route>
  {/each}
</Router>
