import { createInstance } from "localforage";
import { tick } from "svelte";
import { customVideoListManager, snackbarController } from "./basicStores";
import { CustomVideoList } from "./customVideoList";
import { ReactiveObject } from "./ReactiveObject";
import type { RemoteController} from "./RemoteController";
import type { RoomStore } from "./roomStore";


export interface IConnectionManager{
	connected: boolean;
	roomStore?: RoomStore;
	roomName?: string;
	userId?: string;
	ready?: boolean;
	importedInitialData?: boolean
	syncTime?: number;
	synced?: boolean;
	syncing?: boolean;
	remoteController?: RemoteController
}


export class ConnectionManager extends ReactiveObject<IConnectionManager>{
	public loaded: boolean | Promise<boolean>;
	public loading: boolean = false
	public loadedResolver: ((val:boolean) => void) | undefined;
	public storage = createInstance({
		name: "connection-manager-storage"
	})

	public events = new EventTarget();
	public persistentKeys: (keyof IConnectionManager)[] = [
		"userId",
		"roomName",
		"connected",
		"importedInitialData",
		"syncTime"
	]
	constructor(options: Partial<IConnectionManager> = {} as any) {
		const defaultOptions:IConnectionManager = {
			connected: false,
			roomStore: undefined,
			roomName: "",
			userId: "",
		};
		super({...defaultOptions, ...options});
		this.loaded = new Promise((res) => {
			this.loadedResolver = res;
		})
	}

	async save(){
		const state = this.get();
		for(let key of this.persistentKeys){
			await this.storage.setItem(key, state[key]);
		}
	}

	async load(){
		if(this.loading) return
		if(typeof this.loaded === "boolean" && this.loaded) return
		console.log("Loading connection manager");
		this.loading = true;
		let data:any = {}
		for(let key of this.persistentKeys){
			const item = await this.storage.getItem(key);
			if(item !== null){
				data[key] = item;
			}
		}
		this.update(state => {
			return {
				...state,
				...data
			}
		})

		this.setItem("ready", true);
		this.loadedResolver?.(true);
		this.loaded = true;
		this.loading = false
		this.loadedResolver = undefined;
	}

	async reconnect(RoomStoreClass: typeof RoomStore){
		const state = this.get();
		state?.roomStore?.destroy();

		if(state.roomName){
			const roomStore = new RoomStoreClass(state.roomName);
			await roomStore.init();
			this.setItem("roomStore", roomStore);
			this.setItem("connected", true);
		}else{
			throw new Error("No room name");
		}
	}

	disconnect(){
		this.get()?.roomStore?.destroy();
		this.setItem("connected", false);
		this.setItem("roomStore", undefined);
	}

	connect(roomName: string, RoomStoreClass: typeof RoomStore){
		this.setItem("roomName", roomName);
		this.reconnect(RoomStoreClass);
	}

	setRemoteController(roomStore: RoomStore, remoteController:RemoteController){
		this.update(state => {
			state.remoteController = remoteController;
			state.roomStore = roomStore;
			return state
		})
	}


	async syncPlaylists(filterList?: (listId: string) => boolean){

		try{
			if(this.getItem("syncing")){
				return;
			}
			this.setItem("syncing", true);
			const {remoteController} = this.get(); 
			snackbarController.add({
				id: "syncingPlaylists",
				message: "Syncing playlists... Could take a while",
				duration: 60 * 1000 * 10
			})
	
			await customVideoListManager.loadConfig();
			await customVideoListManager.loadLists();
	
			const lists = await remoteController?.getAllPlaylistData({
				onProgress: (stats) => {
					snackbarController.updateSnackbar("syncingPlaylists", snackbar => {
						snackbar.message = `Syncing playlists... Playlists: (${
							stats.currentPlaylistCount
						}/${
							stats.totalPlaylists
						}) done - Videos: (${
							stats.currentPlaylistVideoCount
						}/${
							stats.totalVideosInCurrentPlaylist
						})`;
						return snackbar;
					})
				},
				getCustomListOptions: {
					hasVideoInLocal: async (listId, videoId, addedAt) => {
						const list =await customVideoListManager.getAndLoadList(listId)
	
						if(!list) return false;
						
						const hasVideo = list.getItemByVideoId(videoId);
	
						if(!hasVideo) return false;
	
						return true
					}
				},
				filterList,
			})
			snackbarController.clear();
			await tick()
	
			if(!lists){
				snackbarController.add({
					message: "Could not sync playlists"
				})
				return;
			}
	
	
			for(let list of lists){
				const listId = list.id;
				if(!customVideoListManager.getList(listId)){
					await customVideoListManager.newList(list)
				}else{
					const customListInstance = await customVideoListManager.getAndLoadList(listId);
					const newList = new CustomVideoList(list);
					await customListInstance?.merge(newList);
				}
			}
			snackbarController.add({
				message: "Syncing playlists... Done",
			})
	
			this.setItem("synced", true);
		}catch(err){
			console.error(err);
			this.setItem("syncing", false);
		}
	}


	enablePlaylistsRealtimeSync(){
		const addListeners = (lists: CustomVideoList[]) => {
			const removeListeners = lists.map(list => {
				const removeUpdateListener = list.addListener("update", ({
					detail: {
						item
					}
				}) => {
					const rpc = this.get()?.remoteController?.rpc
					if(!rpc) return;
					rpc.call("updateVideoInList", {
						list: list.getStateWithoutVideos(),
						video: item
					})
				})

				const removeRemoveListener = list.addListener("remove", ({
					detail: {
						item
					}
				}) => {
					const rpc = this.get()?.remoteController?.rpc
					if(!rpc) return;
					rpc.call("removeVideoFromList", {
						list: list.getStateWithoutVideos(),
						video: item
					})
				})

				const removeAddListener = list.addListener("add", ({
					detail: {
						item
					}
				}) => {
					const rpc = this.get()?.remoteController?.rpc
					if(!rpc) return;
					rpc.callEach("addVideoToList", {
						list: list.getStateWithoutVideos(),
						video: item
					})
				})

				return () => {
					removeUpdateListener();
					removeRemoveListener();
					removeAddListener();
				}
			})

			return () => {
				removeListeners.forEach(removeListener => removeListener())
			}
		}

		let removeListeners = addListeners(customVideoListManager.get().listInstances);

		const unsub = customVideoListManager.subscribe(state => {
			removeListeners();
			removeListeners = addListeners(state.listInstances);
		})

		return () => {
			unsub();
			removeListeners();
		}
	}
}


export interface IMulipleConnectionManager{
	connections: ConnectionManager[];
}
// export class MultipleConnectionManager extends ReactiveObject<IMulipleConnectionManager>{
// 	public storage = createInstance({
// 		name: "MultipleConnectionManager",
// 	})

// 	constructor(){

// 	}
// }