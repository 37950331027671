<script lang="ts">
import { scrollPositionManager } from "@common/basicStores";

	import { onMount, tick } from "svelte";
	import {
		useLocation, type NavigatorLocation
	} from 'svelte-navigator'

	const location = useLocation();


	const handleLocationChange = async(loc: NavigatorLocation) => {
	}

	$: if($scrollPositionManager.currentScrollContainer) handleLocationChange($location)
</script>