import OverlayModalContainer from "@components/Modals/OverlayModalContainer.svelte";
import type { SvelteComponentTyped } from "svelte";
import { ReactiveObject } from "./ReactiveObject";


export interface IOverlayComponent<
	T extends typeof SvelteComponentTyped<any, any, any> = any
> {
	id: string;
	component: T;
	props: ConstructorParameters<T>[0]["props"];
}

export interface IOverlayComponentManager {
	components: IOverlayComponent[];
}

export class OverlayComponentManager extends ReactiveObject<
	IOverlayComponentManager
>{

	constructor(options: Partial<IOverlayComponentManager> = {} as any) {
		const _options: IOverlayComponentManager = {
			components: [],
			...options
		}
		super(_options);
	}

	addComponent<
		T extends typeof SvelteComponentTyped<any, any, any> = any
	>(component: IOverlayComponent<T>) {
		if(this.hasComponent(component.id)){
			return;
		}
		this.setItem("components", this.get().components.concat(component));
		return {
			dispose: () => {
				this.setItem("components", this.get().components.filter(c => c !== component));
			}
		}
	}

	removeComponent(
		id: string
	) {
		this.setItem("components", this.get().components.filter(c => c.id !== id));
	}

	hasComponent(id:string){
		return this.get().components.some(c => c.id === id);
	}

}