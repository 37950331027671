<script lang="ts">
	import ListItemSekeleton from "./ListItemSekeleton.svelte";
	export let count: number = 5;
	export let delay: number = 0.2
</script>

<div class="flex flex-col gap-5 h-full w-full">
	{#each new Array(count).fill(null) as _, i}
		<ListItemSekeleton 
		delay={delay * i}
		duration={delay * count * 1.5}
		/>
	{/each}
</div>
