<script lang="ts">
  import SyncLoader from "@components/Loaders/SyncLoader.svelte";
  import type { SvelteComponentTyped } from "svelte";
  import Loadable from "svelte-loadable/Loadable.svelte";

  type T = $$Generic<{default: typeof SvelteComponentTyped<any, any, any>}>

  interface $$Slots{
    default: {
      component: T["default"]
    }
  }

  export let loader: () => Promise<T>;
  export let loadingComponent: typeof SvelteComponentTyped | undefined =
    undefined;
  export let hideLoader: boolean = false;
  export let hideError: boolean = false;
  $: _loader = loader as any;

  const logError = (err: any) => {
    console.error(err);
    return `${err}`;
  };
</script>

<Loadable let:component loader={_loader}>
  <svelte:fragment slot="loading">
    {#if !hideLoader}
      <div
        class="flex items-center justify-center w-full h-full overflow-hidden bg-base-300"
      >
        {#if loadingComponent}
          <svelte:component this={loadingComponent} />
        {:else}
          <SyncLoader color="white" size={20} />
        {/if}
      </div>
    {/if}
  </svelte:fragment>
  <svelte:fragment slot="error" let:error>
    {#if !hideError}
      <div
        class="justify-center w-full h-full overflow-hidden flex-center bg-base-300 text-base-content"
      >
        {logError(error)}

        <br />
      </div>
    {/if}
  </svelte:fragment>
  <slot {component} />
</Loadable>
