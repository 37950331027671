import { openScannerModal } from "@components/Modals/controllers";
import { connectionManager, videoPlayerInstance } from "./basicStores";
import type { ICastVideo } from "./cast";
import type { IVideoMeta } from "./invidiousApi";
import { ReactiveObject } from "./ReactiveObject";


export interface ILaptopCast{
	isInitialized: boolean;
	connected: boolean;
	currentVideoId?: string;
	volume?: number;
}
export class LaptopCast extends ReactiveObject<ILaptopCast>{
	constructor(options: Partial<ILaptopCast> = {} as any) {
		const _options: ILaptopCast = {
			isInitialized: false,
			connected: false,
			volume: 1,
			...options
		}
		super(_options);
	}

	async init() {
		let connectionManagerState = connectionManager.get();
		if (connectionManagerState.connected) {
			this.setItem("isInitialized", true);
		}else{
			await new Promise<void>((res, rej) => {
				openScannerModal({
					onDone: res,
					onClose: res
				})
			})
			
			connectionManagerState = connectionManager.get();
			
			if (connectionManagerState.connected) {
				this.setItem("isInitialized", true);
			}else{
				throw new Error("Could not connect to remote controller");
			}
		}

		const remoteController = connectionManagerState.remoteController;
		if(remoteController){
			await remoteController.rpc.callEach("initLocalCast", undefined)
			this.setItem("connected", true);
		}
	}

	connect(){

	}
	disconnect(){
		this.update(state => ({
			...state,
			connected: false,
			currentVideoId: undefined,
		}))
	}

	castVideo(video: ICastVideo){

		const client = connectionManager.get().remoteController;
		if(client){
			client.rpc.callEach("castVideo", video)
		}
	}

	setVolume(volume: number, muted: boolean){
		const client = connectionManager.get().remoteController;
		if(client){
			client.rpc.callEach("setVolume", volume)
		}
	}

	getVolume(){
		const client = connectionManager.get().remoteController;
		if(client){
			return client.rpc.call("getVolume", undefined)
		}
	}

	castYoutubeVideo(video: IVideoMeta){
		const client = connectionManager.get().remoteController;
		const player = videoPlayerInstance.get()
		if(client){
			client.rpc.callEach("castYoutubeVideo", {
				meta: video,
				currentTime: player?.currentTime(),
				volume: player?.volume(),
				isPaused: player?.paused()
			})
		}
		this.setItem("currentVideoId", video.videoId)
	}
	play(){
		const client = connectionManager.get().remoteController;
		if(client){
			client.rpc.callEach("play", undefined)
		}
	}
	pause(){
		const client = connectionManager.get().remoteController;
		if(client){
			client.rpc.callEach("pause", undefined)
		}
	}

	seek(time: number){
		const client = connectionManager.get().remoteController;
		if(client){
			client.rpc.callEach("seek", time)
		}
	}

	decreaseVolumeByStep(step: number = 0.1){
		const client = connectionManager.get().remoteController;
		if(client){
			client.rpc.callEach("decreaseVolumeByStep", step )
		}
	}

	increaseVolumeByStep(step: number = 0.1){
		const client = connectionManager.get().remoteController;
		if(client){
			client.rpc.callEach("increaseVolumeByStep", step )
		}
	}
}